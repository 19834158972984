import React, { useState } from "react";
import "./App.css";

import { ethers } from "ethers";
import { Alert, Button, ButtonGroup } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import AuthButton from "./components/AuthButton";
import AuthTestButton from "./components/AuthTestButton";
import Navbar from "./components/Navbar";
import useCheckMobileScreen from "./helper/isMobile";

// quick dirty window.ethereum workaround
declare const window: any;

function App() {
  const hasMetamask = window.ethereum ? true : false;
  console.log(hasMetamask);

  const [log, setLog] = useState("");

  return (
    <div className="App">
      <Navbar>
        {hasMetamask ? (
          <AuthButton
            provider={new ethers.providers.Web3Provider(window.ethereum)}
            onLog={setLog}
          />
        ) : (
          <Button disabled>Install Metamask to Play</Button>
        )}
        {useCheckMobileScreen() ? (
          <Button onClick={() => window.open("")}>Open Mobile App</Button>
        ) : (
          ""
        )}
      </Navbar>
      <header className="App-header">
        {log ? <Alert>{log}</Alert> : ""}
        <ButtonGroup>
          <AuthTestButton onLog={setLog} />
          <Button
            onClick={() =>
              window.open("https://metamask.app.link/dapp/app.dopamining.io")
            }
          >
            Test Dapp Deeplink
          </Button>
          <Button href="https://dopamining.io/test/1">
            Open App with test
          </Button>
          <Button
            onClick={() => {
              window.open("", "_self").close();
            }}
          >
            Close this window
          </Button>
          <Button href="dopamining://authenticated/12345">Open App</Button>
        </ButtonGroup>
      </header>
    </div>
  );
}

export default App;

import { Container, Navbar as BNavbar, Nav } from "react-bootstrap";

export default function Navbar(props: any) {
  return (
    <BNavbar bg="light" expand="lg">
      <Container>
        <BNavbar.Brand href="#home">Dopamining</BNavbar.Brand>
        <BNavbar.Toggle aria-controls="basic-BNavbar-nav" />
        <BNavbar.Collapse id="basic-BNavbar-nav">
          <Nav className="mx-auto">
            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#link">Link</Nav.Link>
          </Nav>
          {props.children}
        </BNavbar.Collapse>
      </Container>
    </BNavbar>
  );
}

interface InputFieldProps {
  placeholder: string;
  type?: "text" | "tel" | "email" | "decimal";
  autoComplete?: string;
  isPassword?: boolean;
}

export function InputField(props: InputFieldProps) {
  return (
    <input
      className="rounded-pill"
      style={{
        backgroundColor: "#F1F4F8",
        border: "none",
        padding: "10px 15px",
        marginBottom: "15px",
        width: "100%",
      }}
      placeholder={props.placeholder || "Nothing"}
      inputMode={props.type || "text"}
      autoComplete={props.autoComplete}
      type={props.isPassword ? "password" : props.type}
    />
  );
}

import { ethers } from "ethers";
import { useState, MouseEvent } from "react";
import Button from "react-bootstrap/Button";

export interface User {
  pubKey: string;
  userType: string;
  document: string;
}

export interface UserData {
  level: number;
}

export interface AuthButtonProps {
  provider: ethers.providers.Web3Provider;
  disabled?: boolean;
  onLog: (message: string) => void;
}

export default function AuthButton(props: AuthButtonProps) {
  const provider = props.provider;
  const [currentVariant, setVariant] = useState("primary");
  const [isDisabled, setDisabled] = useState(props.disabled);

  const [loggedIn, setLoggedIn] = useState(false);

  async function handleAuthenticate(_event: MouseEvent<HTMLButtonElement>) {
    setDisabled(true);

    // MetaMask requires requesting permission to connect users accounts
    await provider.send("eth_requestAccounts", []);

    // The MetaMask plugin also allows signing transactions to
    // send ether and pay to change state within the blockchain.
    // For this, you need the account signer...
    const signer = provider.getSigner();
    const address = await signer.getAddress();

    fetch("https://api.dopamining.io/user/login/" + address, {
      method: "GET",
    })
      .then((res: Response) => {
        if (res.status === 200 || res.status === 201) {
          return res.json();
        } else {
          throw new Error("Could not get user.");
        }
      })
      .then((json: User) => {
        console.log(json);
        if (json && json.pubKey === address) {
          return signer.signMessage(json.document);
        } else {
          props.onLog("WTF, something strange is happening.");
          console.log("WTF, something strange is happening.");
        }
      })
      .then((signedMessage: string | undefined) => {
        if (!signedMessage) throw new Error("Signing in denied.");
        return fetch(
          "https://api.dopamining.io/user/verify/" +
            address +
            "/" +
            signedMessage,
          { method: "GET" }
        );
      })
      .then((res: Response) => {
        if (res.status === 200) return res.json();
        else throw new Error("Signed message not right.");
      })
      .then((json: UserData) => {
        console.log(json);
        setVariant("success");
        setLoggedIn(true);
      })
      .catch((err: Error) => {
        console.error(err);
        props.onLog(err.message);
        setVariant("danger");
      })
      .finally(() => {
        setDisabled(false);
      });
    console.log(address);
  }

  function handleLogout(_event: MouseEvent<HTMLButtonElement>) {
    fetch("https://api.dopamining.io/user/logout", {
      method: "GET",
    });
  }

  return (
    <>
      {!loggedIn ? (
        <Button
          disabled={isDisabled}
          variant={currentVariant}
          onClick={handleAuthenticate}
        >
          Start Playing
        </Button>
      ) : (
        <Button variant="dark" onClick={handleLogout}>
          Log Out
        </Button>
      )}
    </>
  );
}

import { InputField } from "../components/InputField";
import { ethers } from "ethers";

export function SignUp(props: any) {
  // @ts-ignore
  const hasMetamask = window.ethereum ? true : false;

  function _signUp() {
    let provider: ethers.providers.Web3Provider;
    try {
      // @ts-ignore
      provider = new ethers.providers.Web3Provider(window.ethereum);
    } catch (error) {
      document.getElementById("body")?.classList.add("bg-danger");
      return;
    }

    // MetaMask requires requesting permission to connect users accounts
    provider.send("eth_requestAccounts", []).then(async (res: string[]) => {
      // The MetaMask plugin also allows signing transactions to
      // send ether and pay to change state within the blockchain.
      // For this, you need the account signer...
      const signer = provider.getSigner();
      const address = await signer.getAddress();

      fetch("https://api.dopamining.io/user/signup/" + address, {
        method: "POST",
        body: JSON.stringify({ hello: "yeah" }),
      });
    });
  }

  return (
    <div className="container">
      <div className="row">
        <div className="mx-auto col-10 col-sm-8 col-md-6 col-lg-5 col-xl-4">
          <h1
            style={{
              fontWeight: 600,
              textAlign: "center",
              marginBottom: "20px",
              marginTop: "50px",
            }}
          >
            Sign Up
          </h1>
          <InputField
            placeholder="Username"
            type="text"
            autoComplete="username"
          />
          <InputField placeholder="Email" autoComplete="email" />
          <InputField placeholder="Phone" type="tel" autoComplete="tel" />
          <InputField
            placeholder="Password"
            isPassword
            autoComplete="new-password"
          />
          <div className="tos mb-3">
            <input type="radio" name="acceptConditions" id="acceptConditions" />
            {"   "}
            <label
              htmlFor="acceptConditions"
              style={{ color: "#9299A3", fontSize: "12px" }}
            >
              By continuing you accept our Privacy Policy
            </label>
          </div>
          <button
            className="btn rounded-pill border-0 p-2 w-100"
            onClick={_signUp}
            disabled={!hasMetamask}
            style={{
              background:
                "linear-gradient(90deg, rgba(120,80,191,1) 0%, rgba(81,45,168,1) 100%)",
              color: "white",
            }}
          >
            Sign Up
          </button>
        </div>
      </div>
      <div
        className="ftr"
        style={{
          position: "fixed",
          bottom: "20px",
          width: "100vw",
          textAlign: "center",
        }}
      >
        <span style={{ color: "#404B52", fontWeight: 300 }}>
          Already have an account?
        </span>{" "}
        <a
          href="/#/login"
          style={{
            color: "#461B93",
            textDecoration: "none",
            fontWeight: 600,
          }}
        >
          Sign in
        </a>
      </div>
    </div>
  );
}

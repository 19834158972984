import Button from "react-bootstrap/esm/Button";

interface AuthTestButtonProps {
  onLog: (message: string) => void;
}

export default function AuthTestButton(props: AuthTestButtonProps) {
  function handleTestRoute() {
    fetch("https://api.dopamining.io/user/test", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((json) => {
        props.onLog(JSON.stringify(json));
      })
      .catch((err) => console.error(err));
  }
  return <Button onClick={handleTestRoute}>Test Authenticated Route</Button>;
}

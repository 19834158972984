import { Web3Provider } from "@ethersproject/providers";
import { ethers } from "ethers";
import { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { User, UserData } from "../components/AuthButton";

// quick dirty window.ethereum workaround
declare const window: any;

export function LogIn(props: any) {
  const [reqAccSuccessful, setReqAcc] = useState(false);
  const [loginRequestSuccessful, setLoginRequest] = useState(false);
  const [retrAccSuccessful, setRetrAcc] = useState(false);

  function signWithMetamask() {
    let provider: Web3Provider;
    try {
      provider = new ethers.providers.Web3Provider(window.ethereum);
    } catch (error) {
      document.getElementById("body")?.classList.add("bg-danger");
      return;
    }

    // MetaMask requires requesting permission to connect users accounts
    provider.send("eth_requestAccounts", []).then(async (res) => {
      // The MetaMask plugin also allows signing transactions to
      // send ether and pay to change state within the blockchain.
      // For this, you need the account signer...
      const signer = provider.getSigner();
      const address = await signer.getAddress();
      setReqAcc(true);

      fetch("https://api.dopamining.io/user/login/" + address, {
        method: "GET",
      })
        .then((res) => {
          if (res.ok) {
            setLoginRequest(true);
            return res.json();
          } else if (res.status === 404) {
            setLoginRequest(false);
            // window.location.replace("/#/signup/" + address);
            // TODO: Show button to redirect to sign up
          } else {
            setLoginRequest(false);
            throw new Error("Login Request failed.");
          }
        })
        .then((json: User) => {
          console.log(json);
          if (json && json.pubKey === address) {
            setRetrAcc(true);
            return signer.signMessage(json.document);
          } else {
            setRetrAcc(false);
            console.log("WTF, something strange is happening.");
          }
        })
        .then((signedMessage: string | undefined) => {
          if (!signedMessage) throw new Error("Signing in denied.");
          return fetch(
            "https://api.dopamining.io/user/verify/" +
              address +
              "/" +
              signedMessage,
            { method: "GET" }
          );
        })
        .then((res: Response) => {
          if (res.status === 200) return res.json();
          else throw new Error("Signed message not right.");
        })
        .then((json: UserData) => {
          console.log(json);
          document.getElementById("body")?.classList.toggle("bg-success");
        })
        .catch((err) => {
          console.error(err);
        });
    });
  }

  return (
    <div id="body">
      {signWithMetamask()}
      <h1 className="text-center">Dopamining.io</h1>
      <h3 className="text-center">
        We are trying to sign you in. <br /> Metamask should prompt to sign a
        random string.
      </h3>

      <form action="" className="ms-3">
        <input
          checked={reqAccSuccessful}
          disabled
          type="checkbox"
          name="reqAcc"
          id="reqAcc"
        />
        {"  "}
        <label htmlFor="reqAcc"> Allowed to read public key </label>
        <br />
        <input
          checked={loginRequestSuccessful}
          disabled
          type="checkbox"
          name="loginRequest"
          id="loginRequest"
        />
        {"  "}
        <label htmlFor="loginRequest"> Login request successful </label>
        <br />
        <input
          checked={retrAccSuccessful}
          disabled
          type="checkbox"
          name="retrAcc"
          id="retrAcc"
        />
        {"  "}
        <label htmlFor="retrAcc"> Retrival of user account successful </label>
        <br />
        <input
          checked={retrAccSuccessful}
          disabled
          type="checkbox"
          name="retrAcc"
          id="retrAcc"
        />
        {"  "}
        <label htmlFor="retrAcc"> Signing of message successful </label>
      </form>

      {!loginRequestSuccessful && (
        <Alert variant="warning">
          <Alert.Heading>You don't have an account yet.</Alert.Heading>
          <p>You tried to log in without an account.</p>
          <Button
            variant="primary"
            onClick={() => {
              window.location.replace("/#/signup");
            }}
          >
            Sign Up
          </Button>
        </Alert>
      )}
    </div>
  );
}
